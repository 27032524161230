import { type Customer } from "@prisma/client";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Combines multiple class names into a single string.
 *
 * This function merges class names using `clsx` and `twMerge` to ensure that
 * Tailwind CSS classes are properly combined and deduplicated.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Creates a debounced function that delays invoking
 * the provided function until after the specified timeout.
 */
export function debounce<T>(func: (arg: T) => void, timeout: number) {
  let timer: NodeJS.Timeout;
  return (arg: T) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(arg);
    }, timeout);
  };
}

/**
 * Extracts the file extension from a given filename.
 */
export function getFileExtension(filename: string): string {
  const parts = filename.split(".");

  if (parts.length > 1 && parts[0] !== "") {
    return parts.pop() || "";
  }

  return "";
}

/**
 * Rounds a given number to two decimal places.
 */
export const roundAmount = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

/**
 * Retrieves the initials of a customer based on their first and last name.
 */
export function getCustomerInitials(customer: Customer | undefined) {
  if (customer) {
    if (customer.firstName && customer.lastName) {
      return `${customer?.firstName[0]}${customer?.lastName[0]}`;
    }
  }
  return "";
}

/**
 * Splits an array into chunks of the specified size.
 */
export function chunk<T>(array: T[], size: number): T[][] {
  const results: T[][] = [];
  while (array.length) {
    results.push(array.splice(0, size));
  }
  return results;
}

/**
 * Formats a date using the specified format.
 */
export function getMonthName(date: Date): string {
  const monthNamesInSpanish = [
    "Enero",
    "Febrero",
    "Marzo",
    "abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const currentMonthIndex = date.getMonth();
  return monthNamesInSpanish[currentMonthIndex]!;
}
